export default function XucPhoneEventListener($log, $rootScope, $q, XucLink) {
  const _baseEventName = 'XucPhoneEventListener.';
  const _currentCallsEventName = 'CurrentCallsEvents';
  const _EVENT_DIALING = "EventDialing";
  const _EVENT_RINGING = "EventRinging";
  const _EVENT_ESTABLISHED = "EventEstablished";
  const _EVENT_RELEASED = "EventReleased";
  const _EVENT_ONHOLD = "EventOnHold";
  const _EVENT_FAILURE = "EventFailure";
  const _ANY = "Any";

  var handlerUnsubscription;

  var isUsingMobileApp = false;
  $rootScope.$on('isUsingMobileApp', (e, value) => {
    isUsingMobileApp = value;
  });


  var _onCtiPhoneEvent = function(phoneEvent) {
    var eventWithType = _addCallType(phoneEvent);
    $rootScope.$emit(_baseEventName + _ANY, eventWithType);
    $rootScope.$emit(_baseEventName + phoneEvent.eventType, eventWithType);
  };

  var _addCallType = function(event) {
    var getType = (userData) => {
      if (!angular.isUndefined(userData) && !angular.isUndefined(userData.SIPCALLID)) {
        return xc_webrtc.getMediaTypeBySipCallId(event.userData.SIPCALLID);
      } else {
        $log.debug('Unable to get SIP Call Id from event user data, fallback to audio media type', event);
        return xc_webrtc.mediaType.AUDIO;
      }
    };
    var type = getType(event.userData);
    event.callType = (typeof type !== undefined && type !== null) ? type : xc_webrtc.mediaType.AUDIO;
    return event;
  };

  var _onCtiCurrentCallsPhoneEvents = function(currentCallsPhoneEvents) {
    $rootScope.$emit(_baseEventName + _currentCallsEventName, currentCallsPhoneEvents.events);
  };

  const _onWebrtcTerminateEvent = function(event) {
    if(isUsingMobileApp &&
       event.type === xc_webrtc.Incoming.TERMINATED &&
       xc_webrtc.doesSessionExists(event.sipCallId)
    ) {
      let releaseEvt = {eventType: "EventReleased", userData: {SIPCALLID: event.sipCallId}};
      $rootScope.$emit(_baseEventName + _ANY, releaseEvt);
      $rootScope.$emit(_baseEventName + _EVENT_RELEASED, releaseEvt);
    }
  };

  /**
   * Add phone event handler unregistered on scope destroy. The callback will be called upon each PHONEEVENT received.
   * @param scope The scope containing the callback function
   * @param callback The callback function to call upon event reception
   * @param targetEvent The event name to be notified of.
   * One of 'EventRinging', 'EventEstablished', 'EventReleased' or undefined. If undefined, all events are notified
   */
  var _addHandler = function(scope, callback, targetEvent) {
    var unregister = _addHandlerCustom(callback, targetEvent);
    scope.$on('$destroy', unregister);
  };

  /**
   * Add phone event handler and return unregister function. The callback will be called upon each PHONEEVENT received.
   * @param callback The callback function to call upon event reception
   * @param targetEvent The event name to be notified of.
   * @returns unregister function
   * One of 'EventRinging', 'EventEstablished', 'EventReleased' or undefined. If undefined, all events are notified
   */
  var _addHandlerCustom = function(callback, targetEvent) {
    if(typeof(targetEvent) === "undefined") {
      targetEvent = _ANY;
    }
    return $rootScope.$on(_baseEventName + targetEvent, function(event, phoneEvent) {
      $rootScope.$applyAsync(function () {
        if (isUsingMobileApp && phoneEvent && phoneEvent.eventType != _EVENT_RELEASED) {
          _callbackInMultiRingingDeviceContext(callback, phoneEvent);
        } else {
          callback(phoneEvent);
        }
      });
    });
  };

  var _callbackInMultiRingingDeviceContext = function(callback, phoneEvent) {
    if(phoneEvent.userData &&
      xc_webrtc.doesSessionExists(phoneEvent.userData.SIPCALLID)){
      callback(phoneEvent);
    } else {
      $log.debug("PhoneEvent is not propagated as session does not exist in this application", phoneEvent);
    } 
  };

  /**
   * Add dialing event handler.
   * @see _addHandler
   */
  var _addDialingHandler = function(scope, callback) { _addHandler(scope, callback, _EVENT_DIALING); };

  /**
   * Add ringing event handler.
   * @see _addHandler
   */
  var _addRingingHandler = function(scope, callback) { _addHandler(scope, callback, _EVENT_RINGING); };

  /**
   * Add established event handler.
   * @see _addHandler
   */
  var _addEstablishedHandler = function(scope, callback) { _addHandler(scope, callback, _EVENT_ESTABLISHED); };

  /**
   * Add released event handler.
   * @see _addHandler
   */
  var _addReleasedHandler = function(scope, callback) { _addHandler(scope, callback, _EVENT_RELEASED); };

  /**
   * Add on hold event handler.
   * @see _addHandler
   */
  var _addOnHoldHandler = function(scope, callback) { _addHandler(scope, callback, _EVENT_ONHOLD); };

  var _addCurrentCallsPhoneEventsHandler = function(scope, callback) {
    _addHandler(scope, callback, _currentCallsEventName);
  };

  var _requestPhoneEventsForCurrentCalls = function() { Cti.getCurrentCallsPhoneEvents(); };

  var _init = function() {
    $log.info("Starting XucPhoneEventListener service");
    Cti.setHandler(Cti.MessageType.PHONEEVENT, _onCtiPhoneEvent);
    Cti.setHandler(Cti.MessageType.CURRENTCALLSPHONEEVENTS, _onCtiCurrentCallsPhoneEvents);    
    handlerUnsubscription = xc_webrtc.setHandler(xc_webrtc.MessageType.INCOMING, _onWebrtcTerminateEvent);
    XucLink.whenLoggedOut().then(_unInit);
  };

  var _unInit = function () {
    $log.info("Unloading XucPhoneEventListener service");
    isUsingMobileApp = false;
    handlerUnsubscription();
    XucLink.whenLogged().then(_init);
  };

  XucLink.whenLogged().then(_init);

  return {
    EVENT_DIALING: _EVENT_DIALING,
    EVENT_RINGING: _EVENT_RINGING,
    EVENT_ESTABLISHED: _EVENT_ESTABLISHED,
    EVENT_RELEASED: _EVENT_RELEASED,
    EVENT_ONHOLD: _EVENT_ONHOLD,
    EVENT_FAILURE: _EVENT_FAILURE,
    addHandler: _addHandler,
    addHandlerCustom: _addHandlerCustom,
    addDialingHandler: _addDialingHandler,
    addRingingHandler: _addRingingHandler,
    addEstablishedHandler: _addEstablishedHandler,
    addReleasedHandler: _addReleasedHandler,
    addOnHoldHandler: _addOnHoldHandler,
    addCurrentCallsPhoneEventsHandler: _addCurrentCallsPhoneEventsHandler,
    requestPhoneEventsForCurrentCalls: _requestPhoneEventsForCurrentCalls
  };
}
